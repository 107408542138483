@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@800&family=Open+Sans:wght@400;500;700;800&family=Roboto&display=swap");

.root,
#root,
#docs-root {
  --dark: #171717;
  --beige: #fef9f6;
  --yellowLight: #fef9f6;
  --yellow: #fac42a;
  --yellowDark: #d1914c;
  --gray: #727272;
  --grayDark: #707070;
  --grayLight: #e0e1e5;
}

polyline,
path {
  fill: inherit;
  stroke: inherit;
}
.fa-play {
  color: white;
  transition: all 0.4s ease;
}
.fa-play:hover {
  color: #d1914c;
}
.mobileMenu {
  background: red;

  min-height: -webkit-fill-available;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Section1 */
/* .section1-bg-img{
  background-image: url('../src/Icons/profile_pic1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
} */
.sect3Flex {
  margin: 0 90px;
}
.sect3Flex > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 210px;
}
.sect3Flex > div > img {
  margin-bottom: 34px;
}
.sect3Flex > div > p {
  font-size: 20px;
}

#sect3Img1 {
  max-width: 75.6px;
  max-height: 93.4px;
}
#sect3Img2 {
  max-width: 104px;
  max-height: 116.8px;
}
#sect3Img3 {
  max-width: 143.5px;
  max-height: 83.2px;
}
#sect3Img4 {
  max-width: 134.4px;
  max-height: 97.5px;
}
#sect3Img5 {
  max-width: 92.1px;
  max-height: 85px;
}
#sect3Img6 {
  max-width: 102px;
  max-height: 92.2px;
}

.listofText {
  margin: 0 0 0 15px;
  list-style-type: none;
}
.listofText > li {
  text-indent: -15px;
}
.listofText > li:before {
  content: "-";
  padding-right: 10px;
  text-indent: -7px;
}

.listofText > li {
  padding-bottom: 0.5px;
}

.navbar {
  z-index: 1000 !important;
}
.navbar_bot {
  position: relative;
}
.navbar_bot::before {
  border-bottom: 2px solid white;
  width: 90%;
  text: center;
  position: absolute;
  content: "";
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}

player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.textTransition {
  position: relative;
  padding-bottom: 9.5px;
}
.textTransition:hover {
  color: #f7c945;
}

.textTransition::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 0%;
  border-bottom: 2px solid white;
  transition: all 0.2s ease-out;
}

.textTransition:hover::before {
  width: 100%;
  border-bottom: 2px solid #f7c945;
}

.customBtn {
  width: 460px;
  height: 54px;
  font-size: 22px;
  /* padding: 12px 20px; */
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  font-weight: bold;
}
.pBtn {
  width: 293px !important;
  height: 55px !important;
}

.customBtnYellow {
  background-color: white;
  text: white;
}
.customBtnYellow:hover {
  background-color: #fac42a;
}
.customBtnYellow:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
  border-radius: 10px;
}
.customBtnYellow:hover:before {
  bottom: -13px;
  right: -13px;
}

.customBtnYellow:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 15px;
  border: 0px solid white;
  z-index: -1;
  transition: all 0.3s ease;
}
.customBtnYellow:hover:after {
  border: 2px solid white;
  bottom: 13px;
  right: 13px;
}

.customBtnBlack {
  color: white;
  background-color: black;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 33px !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
}

.customBtnBlack:hover {
  background-color: #d1914c;
}
.customBtnBlack::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
  border-radius: 15px;
}
.customBtnBlack:hover::before {
  bottom: -13px;
  right: -13px;
}

.customBtnBlack::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 0px solid black;
  z-index: -1;
  transition: all 0.3s ease;
}
.customBtnBlack:hover::after {
  border: 2px solid black;
  bottom: 13px;
  right: 13px;
}

#error_message {
  color: rgb(226, 27, 27);
  font-weight: 600;
  position: absolute;
  bottom: 8px;
  left: 12px;
  transform: translateY(150%);
  font-size: 16px;
}

.customBtnForm {
  height: 50px !important;
  font-size: 16px !important;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  background-color: black;
}
.customBtnForm:enabled {
  height: 50px !important;
  font-size: 16px !important;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  background-color: black;
}
.customBtnForm:disabled {
  height: 50px !important;
  font-size: 16px !important;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  background-color: gray;
  pointer-events: none;
}

.customBtnForm:hover {
  background-color: #d1914c;
}
.customBtnForm::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
  border-radius: 10px;
}

.customBtnForm:disabled::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: gray;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
  border-radius: 10px;
}
.customBtnForm:hover::before {
  bottom: -13px;
  right: -13px;
}

.customBtnForm::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 0px solid black;
  z-index: -2;
  transition: all 0.3s ease;
}
.customBtnForm:hover::after {
  border: 2px solid black;
  bottom: 13px;
  right: 13px;
}

.primaryBtn {
  margin: 20px 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 13px 25px;
  border-radius: 10px;
  font-size: 0.8rem;
  border: none;
  color: white;
  background: blue;
  transition: all 0.25s ease;
}

.primaryBtn:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px -10px rgba(24, 90, 219, 0.6);
}

.centerPlaybtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #ffffff !important;
  color: blue;
  filter: blue;
}

.playbtn:hover {
  fill: red;
  color: red;
  background-color: red;
}

.adIcon {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.shadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  box-shadow: none;
  transition: all 0.3s ease;
}

.border_top_right {
  border-top: 2px solid #e0e1e5;
  border-right: 2px solid #e0e1e5;
}
.border_top {
  border-top: 2px solid #e0e1e5;
}
.border_right {
  border-right: 2px solid #e0e1e5;
}
.border_right_f {
  border-right: 2px solid #e0e1e5;
}
.border_right_x {
  border-right: 2px solid #e0e1e5;
}
.border_bottom {
  border-bottom: 2px solid #e0e1e5;
}
.border_left {
  border-left: 2px solid #e0e1e5;
}

/* Half border bottom */
.half_border_bottom {
  position: relative;
}

.half_border_bottom::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 45%;
  left: 0;
  border-top: 2px solid #d1914c;
}

.customLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.customLabel > label {
  margin-bottom: 10px;
  font-size: 18px;
}

.customInput {
  width: 26.875rem;
  height: 3.125rem;
  font-size: 15px;
  padding: 0 8px 0px 12px;
  font-weight: normal;

  border-radius: 10px;
  position: relative;
}
input:focus {
  outline: none !important;

  box-shadow: 0 0 5px gray;
}
input[type="text"] {
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.footerTextbox {
  width: 31.75rem;
  height: 3.125rem;
  font-size: 15px;
  line-height: 30px;
  padding: 0 5px;
  border: 2px solid #d1914c;
  border-radius: 10px;
}

.footerButton {
  height: 3.125rem !important;
  width: 12.563rem !important;
  margin-right: -2rem;
  position: absolute !important;
  height: 3rem;
  top: 0;
  right: 0;
}

.footerButton:hover::after {
  width: 0 !important;
  height: 0 !important;
}

.linktextAnimation:hover {
  letter-spacing: 1px;
  font-weight: bold;
  color: #d1914c;
}
.linktextAnimation:hover > .ptextAnimation {
  letter-spacing: 1px;
  font-weight: bold;
  color: #d1914c;
}

.smallTextButton {
  font-size: 14px;
}

@media only screen and (max-width: 1500px) {
  .customInput {
    width: 26.875rem;
    height: 3.125rem;
    font-size: 15px;
    padding: 0 8px 0px 12px;
    font-weight: normal;
  }
}

@media only screen and (max-width: 889px) {
  .textTransition {
    position: relative;
    padding-bottom: 2px;
  }
  .textTransition:hover {
    color: #f7c945;
  }

  .textTransition::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -8px;
    width: 50%;
    left: 0%;
    margin-top: 10px;
    border-bottom: 2px solid white;
    transition: all 0.2s ease-out;
  }

  .textTransition:hover::before {
    width: 100%;
    border-bottom: 2px solid #f7c945;
  }
  .pBtn {
    width: 248px !important;
    padding-top: 50px !important;
    font-size: 16px !important;
    padding: 0 !important;
  }
  .halfBg {
    background: linear-gradient(180deg, #fef9f6 50%, white 50%);
  }
  .customBtn {
    width: 322px;
    height: 50px;
    align-self: left;
    font-size: 16px !important;
  }
  .customBtnBlack {
    color: white;
    background-color: black;
  }

  .customBtnBlack:hover {
    background-color: #d1914c;
  }
  .customBtnBlack::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    z-index: -1;
    border-radius: 12px;
  }
  .customBtnBlack:hover::before {
    bottom: -13px;
    right: -13px;
  }

  .customBtnBlack::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 0px solid black;
    z-index: -1;
    transition: all 0.3s ease;
  }
  .customBtnBlack:hover::after {
    border: 2px solid black;
    bottom: 13px;
    right: 13px;
  }

  .customBtnForm {
    width: 100%;
    height: 45px;
    font-size: 17px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    border: none;
    border-radius: 12px;
    font-weight: bold;
    color: white;
    background-color: black;
    padding: 15px 30px;
  }

  .customBtnForm:hover {
    background-color: #d1914c;
  }
  .customBtnForm::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    z-index: -1;
    border-radius: 12px;
  }
  .customBtnForm:hover::before {
    bottom: -13px;
    right: -13px;
  }

  .customBtnForm::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 0px solid black;
    z-index: -2;
    transition: all 0.3s ease;
  }
  .customBtnForm:hover::after {
    border: 2px solid black;
    bottom: 13px;
    right: 13px;
  }
  .footerTextbox {
    width: 95%;
    height: 50px;
    font-size: 12px;
    padding: 0 5px;
    border: 2px solid #d1914c;
    border-radius: 12px;
  }
  .footerButton {
    width: 151px !important;
    height: 55px;
    text-align: center;
    margin-right: 0;
    font-size: 16px;
    display: inline-block;
    padding: 0;
  }
  .customInput {
    width: 90%;
    height: 50px;
    font-size: 15px;
    padding: 0 8px 0px 12px;
    font-weight: normal;
    border-radius: 10px;
  }
  .border_right_f {
    border-right: 0px solid #e0e1e5;
  }
  .border_right_x {
    border-right: 0px solid #e0e1e5;
  }

  .sect3Flex {
    margin: 0 22px;
  }
  .sect3Flex > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 220px;
  }
  .sect3Flex > div > img {
    margin-bottom: 34px;
  }
  .sect3Flex > div > p {
    font-size: 16px;
    text-align: center;
  }

  #sect3Img1 {
    width: 54px;
    height: 65px;
    margin-bottom: 29.6px;
  }
  #sect3Img2 {
    width: 77px;
    height: 87px;
    margin-bottom: 25.6px;
  }
  #sect3Img3 {
    width: 105px;
    height: 62px;
    margin-bottom: 23px;
  }
  #sect3Img4 {
    width: 99px;
    height: 72px;
    margin-bottom: 23px;
  }
  #sect3Img5 {
    width: 76px;
    height: 69px;
    margin-bottom: 23px;
  }
  #sect3Img6 {
    width: 85px;
    height: 77px;
    margin-bottom: 25.4px;
  }
}
